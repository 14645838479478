import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Layout } from "../../components/layout"
import { GridTwo } from "../../components/grids"
import { H1UppercaseGrey,
    H2UppercaseGrey,
    H2Grey,
    H3Grey,
    H1Grey, } from "../../components/headings"
import styled from "styled-components";
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function JobEntry( props ) {
    const job = props.data.contentfulJob;
    if (job.node_locale === "de") {
        const HeaderElementBG = styled.div`
            width: 100%;
            background-color: #f5f5f5;
        `

        const HeaderElem = styled.div`
            padding: 30px;
            display: inline-block;

            h2 {
                margin-top: 0;
                margin-bottom: 0;
                display: inline;
            }
            @media screen and (max-width: 813px) {
                text-align: center;
                font-size: 0.8rem;
                padding: 3%;
                h2 {
                    font-size: 1.2rem;
                }
            }
        `

        const BackElem = styled.div`
            border: 1px;
            border-right-style: solid;
            border-color: hsla(0,0%,0%,0.2);
            float: left;

            @media screen and (max-width: 813px) {
                display: none;
            }
        `

        const BackElemMobile = styled.div`
            border: 1px;
            padding: 10px;
            border-top-style: solid;
            border-color: hsla(0,0%,0%,0.2);
            text-align: center;
            display: none;

            @media screen and (max-width: 813px) {
                display: block;
            }
        `

        const TextDiv = styled.div`
            padding: 80px;
            text-align: left;

            @media screen and (max-width: 813px) {
                text-align: left;
                padding: 30px;
            }
        `
        const options = {
            renderNode: {
                [BLOCKS.HEADING_1]: (node, children) => <H1UppercaseGrey>{children}</H1UppercaseGrey>,
                [BLOCKS.HEADING_2]: (node, children) => <H1Grey>{children}</H1Grey>,
                [BLOCKS.HEADING_3]: (node, children) => <H2Grey>{children}</H2Grey>,
                [BLOCKS.HEADING_4]: (node, children) => <H3Grey>{children}</H3Grey>,
            }
        }
        return (
            <Layout title={job.jobTitle} lang="de" actSite={"career/"}>
                <HeaderElementBG>
                    <BackElem>
                        <HeaderElem>
                            <H2UppercaseGrey><Link to="/career/#openjobs" style={{ textDecoration: "none" }}>🔙</Link></H2UppercaseGrey>
                        </HeaderElem>
                    </BackElem>
                    <HeaderElem>
                        <H2UppercaseGrey>{job.jobTitle}</H2UppercaseGrey>
                    </HeaderElem>
                </HeaderElementBG>
                <hr style={{ width: "100%", margin: "0", padding: "0" }}></hr>
                <GridTwo
                    left={
                        <TextDiv>
                            {documentToReactComponents(job.jobDescription.json, options)}
                        </TextDiv>
                    }
                    right={
                        job.jobPictures.map((elem) => {
                            return (
                                <Img fluid={elem.fluid} style={{ width: "100%" }}/>
                            )
                        })
                    }
                />
                <HeaderElementBG>
                    <BackElemMobile>
                        <Link to="/career/#openjobs" style={{ color: "grey", textDecoration: "none" }}>Zurück zur Liste aller Jobs...</Link>
                    </BackElemMobile>
                </HeaderElementBG>
            </Layout>
        )
    } else {
        return null;
    }
}



// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
    query($id: String) {
        contentfulJob(id: { eq: $id }) {
            jobTitle
            jobDepartment
            jobDescription {
                json
            }
            jobPictures {
                fluid(maxWidth: 1200, quality: 100) {
                    ...GatsbyContentfulFluid
                    src
                }
            }
            id
            node_locale
        }
    }
`